// Custom Types
import type { CommonStyleProps } from 'features/appBuilder/patterns/types';
import type { TableCellStyleProps } from 'features/appBuilder/patterns/features/table/types/styles';

export const initialPatternCommonStyles: CommonStyleProps = {
  activeBgColor: '#ffffff00',
  activeColor: '#000',
  align: 'right',
  bgColor: '#ffffff00',
  color: '#000',
  direction: 'rtl',
  isActive: true,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  size: 12,
};

export const initialTableCellCommonStyles: TableCellStyleProps = {
  align: 'center',
  bgColor: 'rgba(0,0,0, 0.5)',
  borderColor: 'rgba(0,0,0, 1)',
  color: 'rgba(0,0,0, 1)',
  direction: 'rtl',
  height: 35,
  hoverBgColor: 'rgba(0,0,0, 0.5)',
  hoverBorderColor: 'rgba(0,0,0, 1)',
  hoverColor: 'rgba(0,0,0, 1)',
  imageType: 'item',
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  size: 16,
  width: 120,
  padding: '0px',
  borderTop: true,
  borderRight: true,
  borderBottom: true,
  borderLeft: true,
};
