import * as React from 'react';

// Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface BorderRightIconProps extends SvgIconProps {}

const BorderRightIcon: React.FC<BorderRightIconProps> = (props) => (
  <IconBuilder {...props}>
    <rect
      x='20'
      y='4'
      width='16'
      height='16'
      rx='2'
      transform='rotate(90 20 4)'
      stroke='#AEAEAE'
      stroke-width='2'
      stroke-linecap='round'
      stroke-dasharray='1 3'
      fill='none'
    />
    <rect
      x='20.25'
      y='1.75'
      width='20.5'
      height='1.5'
      rx='0.75'
      transform='rotate(90 20.25 1.75)'
      stroke='#59666D'
      stroke-width='1.5'
    />
  </IconBuilder>
);

export default BorderRightIcon;
