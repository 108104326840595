import * as React from 'react';

// Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface BorderTopIconProps extends SvgIconProps {}

const BorderTopIcon: React.FC<BorderTopIconProps> = (props) => (
  <IconBuilder {...props}>
    <rect
      x='4'
      y='4'
      width='16'
      height='16'
      rx='2'
      stroke='#AEAEAE'
      stroke-width='2'
      stroke-linecap='round'
      stroke-dasharray='1 3'
      fill='none'
    />
    <rect
      x='1.75'
      y='3.75'
      width='20.5'
      height='1.5'
      rx='0.75'
      stroke='#59666D'
      stroke-width='1.5'
    />
  </IconBuilder>
);

export default BorderTopIcon;
