import { useState, type FC } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';

// Core Components
import Dialog from 'core/components/base/feedback/Dialog';
import Box from 'core/components/base/layout/Box';
import EditorResizeIconButton from 'core/components/shared/Editor/Toolbar/IconButton/Resize';
import EditorBubbleMenu from 'core/components/shared/Editor/BubbleMenu';

// Core Utilities
import { editorExtensions } from 'core/components/shared/Editor/extensions';

// Core Styles
import './editor.css';
import EditorToolbar from 'core/components/shared/Editor/Toolbar';

// Module Types
interface EditorProps {
  defaultValue?: string;
  hideToolbar?: boolean;
  readonly?: boolean;
  onBlur?: (html: string) => void;
}

const Editor: FC<EditorProps> = (props) => {
  // Props
  const { readonly, hideToolbar, defaultValue, onBlur } = props;

  // Hooks
  const editor = useEditor({
    content: defaultValue,
    extensions: editorExtensions,
    parseOptions: {
      preserveWhitespace: true,
    },
    onBlur: ({ editor }) => {
      onBlur && onBlur(editor.getHTML());
    },
  });

  const [maximize, setMaximize] = useState(false);

  // Render
  const Core = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        '& > div:first-of-type': {
          '& > div:first-of-type': {
            height: '100%',
          },
          height: '100%',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <EditorContent
          className='customScroll'
          readOnly={readonly}
          editor={editor}
        />
        <EditorBubbleMenu editor={editor} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 'fit-content',
        }}
      >
        <EditorResizeIconButton
          maximize={maximize}
          onClick={() => setMaximize(!maximize)}
        />
        <EditorToolbar hide={hideToolbar} editor={editor} />
      </Box>
    </Box>
  );

  return maximize ? (
    <Dialog
      open
      fullScreen
      sx={{ padding: '2rem' }}
      PaperProps={{ sx: { padding: '1rem', borderRadius: 2 } }}
      onClose={() => setMaximize(false)}
    >
      {Core}
    </Dialog>
  ) : (
    Core
  );
};

export default Editor;
