import * as React from 'react';

// Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface BorderBottomIconProps extends SvgIconProps {}

const BorderBottomIcon: React.FC<BorderBottomIconProps> = (props) => (
  <IconBuilder {...props}>
    <rect
      width='16'
      height='16'
      rx='2'
      transform='matrix(1 0 0 -1 4 20)'
      stroke='#AEAEAE'
      stroke-width='2'
      stroke-linecap='round'
      stroke-dasharray='1 3'
      fill='none'
    />
    <rect
      x='0.75'
      y='-0.75'
      width='20.5'
      height='1.5'
      rx='0.75'
      transform='matrix(1 0 0 -1 1 19.5)'
      stroke='#59666D'
      stroke-width='1.5'
    />
  </IconBuilder>
);

export default BorderBottomIcon;
